<template>
  <span class="badge font-weight-normal" :class="statusClass">{{statusText}}</span>
</template>
<script>
export default {
  name: 'TxnStatus',
  props: ['status'],
  computed: {
    /**
     * OPEN วางเดิมพัน
     * WAITING รอผลเดิมพัน
     * SETTLED จ่ายเงินแล้ว
     * REFUND คืนเงิน
     * REJECT ไม่รับเดิมพัน
     * ADJUST_BET ปรับเดิมพัน
     * ROLLBACK
     * TIPS
     * VOID
     */
    statusClass() {
      return {
        OPEN: 'badge-primary',
        WAITING: 'badge-primary',
        SETTLED: 'badge-success',
        REFUND: 'badge-warning',
        REJECT: 'badge-danger',
        ADJUST_BET: 'badge-info',
        // ROLLBACK: 'badge-primary',
        VOID: ''
      }[this.status]
    },
    statusText() {
      return {
        OPEN: 'รอผลเดิมพัน',
        WAITING: 'รอผลเดิมพัน',
        SETTLED: 'จ่ายเงินแล้ว',
        REFUND: 'คืนเงิน',
        REJECT: 'ไม่รับเดิมพัน',
        ADJUST_BET: 'ปรับยอดเดิมพัน',
        // ROLLBACK: 'ROLLBACK',
        VOID: 'โมฆะ'
      }[this.status]
    }
  }
}
</script>
<style lang="scss" scoped>
.badge {
  font-size: 90% !important;
}
</style>
